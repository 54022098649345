const MODULE_OPTION = { OFF: 'A', OFF_FOR_USER: 'B', READ_ONLY: 'C', ON: 'D' } as const
const ATTRIBUTE_OPTION = { OFF: 0, ON: 1 } as const

export const MODULES = {
  SOURCE_LIST_MODULE: MODULE_OPTION,
  FEED_MODULE: MODULE_OPTION,
  ALERT_MODULE: MODULE_OPTION,
  SETTING_MODULE: MODULE_OPTION,
  REPORT_MODULE: MODULE_OPTION,
  STATISTICS_MODULE: MODULE_OPTION,
  TRASH_MODULE: MODULE_OPTION,
  CREATE_ARTICLE_MODULE: MODULE_OPTION,
  PROFILE_MODULE: MODULE_OPTION,
  TAG_MODULE: MODULE_OPTION,
  CONTACT_MODULE: MODULE_OPTION,
  SIMILAR_SETTING_VISIBLE: ATTRIBUTE_OPTION,
  SHOW_COPYRIGHT_DISCLAIMER: ATTRIBUTE_OPTION,
  STATISTICS_ENABLE_EAC_AND_CIRCULATION: ATTRIBUTE_OPTION,
  ENABLE_TEXT_SMS_ALERTS: ATTRIBUTE_OPTION,
  LIMITED_ADD_CONTENT: ATTRIBUTE_OPTION,
  ENABLE_REACH_ON_ARTICLES: ATTRIBUTE_OPTION,
  NON_PDF_REPORT_ACCESS: ATTRIBUTE_OPTION,
  DISPLAY_PRINT_PDF_LINK: ATTRIBUTE_OPTION,
  LIMITED_SEARCH: ATTRIBUTE_OPTION,
  ENABLE_CHAT: ATTRIBUTE_OPTION,
} as const

export type Modules = keyof typeof MODULES

export type Options =
  | typeof MODULE_OPTION[keyof typeof MODULE_OPTION]
  | typeof ATTRIBUTE_OPTION[keyof typeof ATTRIBUTE_OPTION]
