// @ts-nocheck

import R from 'ramda'
import dayjs from 'dayjs'

import { eqFilters, getUnmatchedString, invertFilter } from '../opoint/search/index'
import { identicalReducer, preprocessArticles, articleId } from '../opoint/articles/index'
import { profileListToProfileTree, profileHistorySegmentation, findParents } from '../opoint/profiles/index'
import * as Actions from '../constants/actionTypes'
import type {
  Action,
  DebugInfo,
  MultipleSuggestions,
  Profile,
  ProfileDetail,
  SearchItem,
  Suggestion,
  TBList,
} from '../opoint/flow'

type ProfilesState = {
  list: Array<Profile>
  tree: Array<Profile>
  profileEditorDebug: DebugInfo | {}
  editedProfile: ProfileDetail | null
  activeProfileEditorLine: number
  // each profile line maps to an object of
  // TODO make this a special flow type and merge with suggestions from reducers/search.js
  profileEditorInlineSuggestions: { [key: string]: Array<Suggestion> }
  profileEditorArticles: Array<any>
  profileEditorActiveArticle: { index: number; source: string }
  profileEditorArticlesIdentical: {}
  profileEditorSuggestions: { [key: string]: MultipleSuggestions }
  profileEditorFiltersOpen: boolean
  profileEditorSaveInProgress: boolean
  clickedFilterName: string
  filterMetadata: TBList | null
  filterMetadataFetchInProgress: boolean
  filterMetadataFetchStatus: number
  clickedFilterType: string
  profilesToDelete: Array<number>
  profileHistoryExpanded: boolean
  deletedProfilesExpanded: boolean
  showMoreHistorySegment: number
  profileEditorFiltersShowMore: any
  isProfilePreviewOpened: boolean
  isProfileEditorShownInPreview: boolean
}

export const initialState: ProfilesState = {
  activeProfileEditorLine: 0,
  clickedFilterName: '',
  clickedFilterType: '',
  profilesToDeleteDeps: [],
  deletedProfilesExpanded: false,
  editedProfile: null,
  filterMetadata: null,
  filterMetadataFetchInProgress: false,
  filterMetadataFetchStatus: 0,
  list: [],
  profileEditorActiveArticle: { index: null, source: null },
  profileEditorArticles: [],
  profileEditorArticlesIdentical: {},
  profileEditorDebug: {},
  profileEditorFiltersOpen: false,
  profileEditorInlineSuggestions: {},
  profileEditorSaveInProgress: false,
  profileEditorSuggestions: {},
  profileEditorFiltersShowMore: [],
  profileHistoryExpanded: false,
  profilesToDelete: [],
  showMoreHistorySegment: 1,
  tree: [],
  profileEditorFiltersShowMore: [],
  isProfilePreviewOpened: false,
  isProfileEditorShownInPreview: false,
}

// TODO move this to Opoint libs BEGIN
const isSearchlineEmpty = ({ searchline: { searchterm, filters } }: SearchItem) =>
  R.isEmpty(searchterm) && R.isEmpty(filters)

const emptyProfileLine = (linemode) => ({
  searchline: {
    searchterm: '',
    filters: [],
  },
  linemode,
})
// TODO move this to Opoint libs END

/**
 * Transforms gotten api data to array of categories which conteins title
 * of category and array of corresponding data;
 * Sorts rank categoty by ranks (not by count as default);
 * Filters required categories
 */
export const metadataOrganization = R.compose(
  R.map(([categoryName, categoryContent]) => ({
    title: categoryName,
    content: categoryContent,
  })),
  R.toPairs,
  R.evolve({
    Rank: R.sortBy(R.prop('subjectName')),
  }),
  R.pick(['Rank', 'Speaker', 'Location', 'Format']),
  R.groupBy((topic) => topic.metacatName),
)

export const siteDatesOrganization = R.compose(
  R.map((datePair) => [dayjs(datePair[0]).format('Do MMM'), datePair[1]]),
  R.sort((a, b) => {
    const aDate = new Date(a[0])
    const bDate = new Date(b[0])
    return bDate - aDate
  }),
  R.toPairs,
)

export const totalArticlesCount = R.compose(R.sum, R.values)

export const siteRank = R.compose(R.join(', '), R.map(R.prop('subjectName')), R.filter(R.propEq('metacatName', 'Rank')))

const profilesReducer = (state: ProfilesState = initialState, { type, payload }: Action<any>) => {
  const editedProfileItems = R.lensPath(['editedProfile', 'items'])

  switch (type) {
    case Actions.PROFILES_FETCH_SUCCESS: {
      const profiles = payload

      const expandedProfiles = state.list.filter((profile) => profile.expanded === true)
      // if there are any expanded profiles we should expand them in the new data as well
      if (expandedProfiles.length > 0) {
        const expandedProfilesArray = expandedProfiles.map((el) => el.id)
        const editedProfiles = JSON.parse(JSON.stringify(profiles))
        editedProfiles.forEach((element: Profile) => {
          if (expandedProfilesArray.indexOf(element.id) !== -1) {
            element.expanded = true
          }
        })

        return R.compose(R.assoc('tree', profileListToProfileTree(profiles)), R.assoc('list', editedProfiles))(state)
      }

      return R.compose(R.assoc('tree', profileListToProfileTree(profiles)), R.assoc('list', profiles))(state)
    }

    case Actions.SEARCHTERM_CHANGED_PROFILE_EDITOR: {
      const { id, text } = payload
      const updatedItemLens = R.compose(editedProfileItems, R.lensIndex(id))
      return R.over(updatedItemLens, R.assocPath(['searchline', 'searchterm'], text))(state)
    }

    case Actions.PROFILE_EDITOR_INVALID_SEARCHLINE: {
      const { debug } = payload
      return R.compose(R.assoc('profileEditorDebug', debug), R.assoc('profileEditorSaveInProgress', false))(state)
    }
    case Actions.PROFILE_EDITOR_CLEAR_DEBUG: {
      return R.assoc('profileEditorDebug', {}, state)
    }
    case Actions.SEARCHDATA_CLEAR_PROFILE_EDITOR:
      // TODO this can be written in a much better way
      return R.over(editedProfileItems, (items) =>
        items.reduce((acc, item, index) => {
          if (index === payload.id) {
            if (isSearchlineEmpty(item)) {
              return acc
            }
            return R.append(
              R.merge(item, {
                searchline: {
                  searchterm: '',
                  filters: [],
                },
              }),
              acc,
            )
          }
          return R.append(item, acc)
        }, []),
      )(state)

    case Actions.ADD_PROFILE_EDITOR_LINE:
      return R.over(editedProfileItems, R.append(emptyProfileLine(payload)), state)

    case Actions.PROFILE_EDITOR_FOCUSED_LINE_CHANGED:
      return R.assoc('activeProfileEditorLine', payload.id, state)

    case Actions.SEARCH_GO_TO_EDIT_PROFILE:
      return R.compose(R.assoc('isProfilePreviewOpened', false), R.assoc('isProfileEditorShownInPreview', false))(state)

    case Actions.LOAD_EDITED_PROFILE_SUCCESS:
      return R.evolve(
        {
          profileHistoryExpanded: R.F,
          deletedProfilesExpanded: R.F,
          showMoreHistorySegment: R.always(1),
          editedProfile: R.always(payload),
          activeProfileEditorLine: R.always(0),
          profileEditorArticlesIdentical: R.always({}),
          profileEditorArticles: R.always([]),
        },
        state,
      )

    case Actions.PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_SUCCESS: {
      const { id, results } = payload
      return R.assocPath(['profileEditorSuggestions', id], results)(state)
    }

    case Actions.PROFILE_EDITOR_FILTERS_POP:
      return R.assoc(
        'profileEditorFiltersShowMore',
        state.profileEditorFiltersShowMore.slice(0, state.profileEditorFiltersShowMore.length - 1),
        state,
      )

    case Actions.PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_OF_TYPE_SUCCESS: {
      return R.assoc('profileEditorFiltersShowMore', payload, state)
    }

    case Actions.LEAVE_PROFILE_EDITOR:
      return R.evolve(
        {
          profileEditorSaveInProgress: R.F,
          profileHistoryExpanded: R.F,
          deletedProfilesExpanded: R.F,
          showMoreHistorySegment: R.always(1),
          editedProfile: R.always(null),
          activeProfileEditorLine: R.always(0),
          profileEditorArticles: R.always([]),
          profileEditorFiltersOpen: R.always(false),
        },
        state,
      )

    case Actions.FILTER_METAS_HIDE_DETAIL:
      return R.evolve({
        clickedFilterName: R.always(''),
        filterMetadata: R.always(null),
        filterMetadataFetchStatus: R.always(0),
      })(state)

    case Actions.FILTER_METAS_FETCH:
      return R.evolve({
        filterMetadata: R.always(null),
        filterMetadataFetchInProgress: R.T,
        filterMetadataFetchStatus: R.always(0),
      })(state)

    case Actions.FILTER_METAS_FETCH_SUCCESS: {
      const { filterMetadata, name, type } = payload
      let processedMetas
      switch (type) {
        case 'list':
        case 'tblist':
          processedMetas = filterMetadata
          break
        case 'site':
          processedMetas = filterMetadata.map((filter) => ({
            sortedDates: siteDatesOrganization(filter.metas.countsByDate),
            rank: siteRank(filter.metas.siteSubject),
            site: filter.metas.site,
            totalCountLastDays: totalArticlesCount(filter.metas.countsByDate),
          }))
          break
        case 'lang':
          processedMetas = metadataOrganization(filterMetadata.metas.lang)
          break
        default:
          processedMetas = metadataOrganization(filterMetadata.metas.topic)
      }

      return R.evolve({
        clickedFilterName: R.always(name),
        clickedFilterType: R.always(type),
        filterMetadata: R.always(processedMetas),
        filterMetadataFetchInProgress: R.F,
        filterMetadataFetchStatus: R.always(200),
      })(state)
    }

    case Actions.FILTER_METAS_FETCH_FAILURE: {
      const { status } = payload
      return R.evolve({
        clickedFilterName: R.always(''),
        filterMetadata: R.always(null),
        filterMetadataFetchInProgress: R.F,
        filterMetadataFetchStatus: R.always(status),
      })(state)
    }

    case Actions.DELETE_PROFILES_MODE_TOGGLE:
    case Actions.MANAGE_PROFILES_MODAL_CLOSE:
      return R.assoc('profilesToDelete', [], state)

    case Actions.PROFILE_MARK_FOR_DELETE_MODE: {
      const id = payload
      return R.evolve({
        profilesToDelete: R.ifElse(R.contains(id), R.without([id]), R.append(id)),
      })(state)
    }

    case Actions.PROFILES_GET_DEPENDENCIES_SUCCESS: {
      const { deps } = payload

      deps.forEach((dependency) => {
        dependency.deps.profiles = state.list.filter(({ id }) => dependency.deps.profiles.indexOf(id) !== -1)
      })

      return R.assoc('profilesToDeleteDeps', R.indexBy(R.prop('id'), deps), state)
    }

    // case Actions.PROFILE_DELETE: {
    //   const { profileIds } = payload

    //   return R.evolve({
    //     profilesToDelete: R.reject(id => R.contains(id, profileIds)),
    //   })(state)
    // }

    case Actions.PROFILE_DELETE_SUCCESS:
      return R.evolve({
        list: R.reject(R.propEq('id', payload)),
        profilesToDelete: R.without([payload]),
        editedProfile: (editedProfile) => {
          if (editedProfile && editedProfile.id === payload) {
            return null
          }

          return editedProfile
        },
      })(state)

    case Actions.PROFILE_EDITOR_FILTERS_TOGGLE:
      return R.assoc('profileEditorFiltersOpen', !state.profileEditorFiltersOpen, state)

    case Actions.PROFILE_EDITOR_FILTER_ADDED: {
      const { id, filter } = payload
      return R.evolve({
        editedProfile: {
          items: R.over(
            R.lensIndex(id),
            R.evolve({
              searchline: {
                filters: R.when(R.none(eqFilters(filter)), R.append(filter)),
                searchterm: getUnmatchedString(filter),
              },
            }),
          ),
        },
      })(state)
    }

    case Actions.PROFILE_EDITOR_SEARCHFILTER_TOGGLE: {
      const filter = payload
      const isToggledFilter = eqFilters(filter)

      if (!state.editedProfile.items[state.activeProfileEditorLine]) {
        return state
      }

      //Use basket: prefix in seartchterm for entities of type tag
      if (filter.type === 'tag') {
        const searchterm = `basket:${filter.id}`
        return R.evolve({
          editedProfile: {
            items: R.over(
              R.lensIndex(state.activeProfileEditorLine),
              R.evolve({
                searchline: {
                  searchterm: R.ifElse(
                    (s) => s.includes(searchterm),
                    R.replace(searchterm, ''),
                    R.concat(`${searchterm} `),
                  ),
                },
              }),
            ),
          },
        })(state)
      }

      const activeLineFiltersLens = R.compose(
        editedProfileItems,
        R.lensIndex(state.activeProfileEditorLine),
        R.lensPath(['searchline', 'filters']),
      )
      const isFilterIncluded = R.compose(R.any(isToggledFilter), R.view(activeLineFiltersLens))(state)

      return R.evolve({
        editedProfile: {
          items: R.over(
            R.lensIndex(state.activeProfileEditorLine),
            R.evolve({
              searchline: {
                filters: R.ifElse(R.always(isFilterIncluded), R.reject(isToggledFilter), R.append(filter)),
                searchterm: R.unless(R.always(isFilterIncluded), getUnmatchedString(filter)),
                // TODO test that we only change the searchterm if the filter is added,
                // not when it's removed
                // TODO this needs to be done in the regular search as well!!!!
              },
            }),
          ),
        },
      })(state)
    }

    case Actions.PROFILE_EDITOR_FILTER_REMOVED: {
      const { id, filter } = payload
      return R.over(
        R.lensPath(['editedProfile', 'items', id, 'searchline', 'filters']),
        R.reject(eqFilters(filter)),
        state,
      )
    }

    case Actions.PROFILE_EDITOR_FILTERS_FETCH_SIMPLE_SUCCESS: {
      const { id, results } = payload
      return R.set(R.lensPath(['profileEditorInlineSuggestions', id]), results, state)
    }

    // TODO needs tests
    case Actions.PROFILE_EDITOR_FILTER_INVERTED: {
      const { id, filter } = payload
      return R.over(
        R.compose(R.lensPath(['editedProfile', 'items']), R.lensIndex(id), R.lensPath(['searchline', 'filters'])),
        R.map(R.when(eqFilters(filter), invertFilter)),
        state,
      )
    }

    case Actions.PROFILE_EDITOR_PREVIEW_CLEAR:
      return R.evolve({
        profileEditorArticlesIdentical: R.always({}),
        profileEditorArticles: R.always([]),
        isProfilePreviewOpened: R.always(false),
        isProfileEditorShownInPreview: R.always(false),
      })(state)

    case Actions.PROFILE_EDITOR_PREVIEW_TOGGLE_EDITOR:
      return R.evolve({
        isProfileEditorShownInPreview: R.always(!state.isProfileEditorShownInPreview),
      })(state)

    case Actions.PROFILE_EDITOR_PREVIEW_SUCCESS: {
      const {
        searchresult: { document },
      } = payload
      if (!document || document.length === 0) {
        return R.evolve({
          profileEditorArticlesIdentical: R.always({}),
          profileEditorArticles: R.always([]),
          isProfilePreviewOpened: R.always(false),
        })(state)
      }

      const interceptedDocuments = preprocessArticles(document)

      return R.evolve({
        /* eslint-disable-next-line no-underscore-dangle */
        profileEditorArticlesIdentical: R.reduce(identicalReducer, R.__, document),
        profileEditorArticles: R.always(interceptedDocuments),
        profileEditorActiveArticle: R.always({ index: 0, source: 'click' }),
        isProfilePreviewOpened: R.always(true),
      })(state)
    }

    case Actions.FETCH_MORE_PREVIEW_ARTICLES_SUCCESS: {
      const interceptedDocuments = preprocessArticles(payload.response.searchresult.document)
      return R.evolve({
        /* eslint-disable-next-line no-underscore-dangle */
        profileEditorArticlesIdentical: R.reduce(identicalReducer, R.__, payload.response.searchresult.document),
        profileEditorArticles: R.compose(
          R.uniqBy(articleId),
          /* eslint-disable-next-line no-underscore-dangle */
          R.concat(R.__, interceptedDocuments),
        ),
      })(state)
    }

    case Actions.SET_ACTIVE_PROFILE_EDITOR_PREVIEW_ARTICLE: {
      const { source, index } = payload
      return R.evolve(
        {
          profileEditorActiveArticle: {
            index: R.always(R.clamp(0, state.profileEditorArticles.length, index)),
            source: R.always(source),
          },
        },
        state,
      )
    }

    case Actions.NEXT_PROFILE_EDITOR_PREVIEW_ACTIVE_ARTICLE:
      return R.evolve({
        profileEditorActiveArticle: {
          index: R.compose(R.clamp(0, state.profileEditorArticles.length), R.inc),
          source: R.always('keyPress'),
        },
      })(state)

    case Actions.PREVIOUS_PROFILE_EDITOR_PREVIEW_ACTIVE_ARTICLE:
      return R.evolve({
        profileEditorActiveArticle: {
          index: R.compose(R.clamp(0, state.profileEditorArticles.length), R.dec),
          source: R.always('keyPress'),
        },
      })(state)

    case Actions.SET_ACTIVE_PROFILE_EDITOR_PREVIEW_IDENTICAL: {
      const { article, index } = payload
      return R.evolve(
        {
          profileEditorArticlesIdentical: {
            [articleId(article)]: R.always(index),
          },
        },
        state,
      )
    }

    case Actions.NEXT_PROFILE_EDITOR_PREVIEW_IDENTICAL: {
      const identicalArticles = payload.identical_documents.document

      return R.evolve(
        {
          profileEditorArticlesIdentical: {
            [articleId(payload)]: R.compose(
              /* eslint-disable-next-line no-underscore-dangle */
              R.modulo(R.__, identicalArticles.length),
              R.inc,
            ),
          },
        },
        state,
      )
    }

    case Actions.PREVIOUS_PROFILE_EDITOR_PREVIEW_IDENTICAL: {
      const identicalArticles = payload.identical_documents.document

      return R.evolve(
        {
          profileEditorArticlesIdentical: {
            [articleId(payload)]: R.compose(
              /* eslint-disable-next-line no-underscore-dangle */
              R.modulo(R.__, identicalArticles.length),
              R.dec,
              R.add(identicalArticles.length),
            ),
          },
        },
        state,
      )
    }

    // case Actions.PROFILE_EDITOR_SAVE_PROFILE_SUCCESS: {
    //   const id = payload.profile.id
    //   const idx = R.findIndex(R.propEq('id', id), state.list)
    //   return R.over(R.lensPath(['list', idx, 'expanded']), true, state)
    // }

    case Actions.PROFILE_EDITOR_SAVE_PROFILE:
      return R.assoc('profileEditorSaveInProgress', true, state)

    // case Actions.LEAVE_PROFILE_EDITOR:
    // case Actions.PROFILE_EDITOR_SAVE_PROFILE_SUCCESS:
    // this is done via LEAVE_PROFILE_EDITOR action
    case Actions.PROFILE_EDITOR_SAVE_PROFILE_FAILURE:
      return R.compose(R.assoc('profileEditorSaveInProgress', false))(state)
    case Actions.PROFILE_EDITOR_FIX_SEARCHTERM: {
      const { id, searchterm } = payload
      return R.compose(
        R.assocPath(['profileEditorDebug', 'lines', id], undefined),
        R.assocPath(['editedProfile', 'items', id, 'searchline', 'searchterm'], searchterm),
      )(state)
    }

    case Actions.PROFILE_EXPAND_HISTORY:
      return R.compose(R.assoc('profileHistoryExpanded', true), R.assoc('deletedProfilesExpanded', false))(state)
    case Actions.DELETED_PROFILES_EXPAND:
      return R.compose(R.assoc('profileHistoryExpanded', false), R.assoc('deletedProfilesExpanded', true))(state)
    case Actions.PROFILE_EDITOR_BACK_TO_SEARCH:
      return R.compose(
        R.assoc('isProfilePreviewOpened', false),
        R.assoc('showMoreHistorySegment', 1),
        R.assoc('profileHistoryExpanded', false),
        R.assoc('deletedProfilesExpanded', false),
        R.assoc('isProfileEditorShownInPreview', false),
        R.assocPath(['editedProfile', 'history'], null),
      )(state)
    case Actions.PROFILE_SHRINK_HISTORY:
      return R.compose(
        R.assoc('showMoreHistorySegment', 1),
        R.assoc('profileHistoryExpanded', false),
        R.assoc('deletedProfilesExpanded', false),
        R.assocPath(['editedProfile', 'history'], null),
      )(state)
    case Actions.PROFILE_HISTORY_FETCH_SUCCESS:
    case Actions.DELETED_PROFILES_HISTORY_FETCH_SUCCESS: {
      const {
        history: { count, results },
      } = payload
      const processedResults = profileHistorySegmentation(results)
      return R.compose(
        R.assocPath(['editedProfile', 'history', 'count'], count),
        R.assocPath(['editedProfile', 'history', 'results'], processedResults[0]),
        R.assocPath(['editedProfile', 'allHistoryResults'], processedResults),
      )(state)
    }
    case Actions.PROFILE_SET_OLD_VERSION: {
      const { timestamp } = payload
      const { items } = R.find(R.propEq('timestamp', timestamp), R.path(['editedProfile', 'history', 'results'], state))
      // We must dissociate id of each filter in this profile to avoid discrepancy
      // Backend will create new id for each filter.
      const newItems = R.map(R.dissoc('id'), items)
      return R.evolve({
        editedProfile: {
          items: R.always(newItems),
        },
      })(state)
    }
    case Actions.PROFILE_HISTORY_SHOW_MORE: {
      const resultsToShow = R.lensPath(['editedProfile', 'history', 'results'])
      const addSegment = R.nth(state.showMoreHistorySegment, state.editedProfile.allHistoryResults)
      return R.when(
        /* eslint-disable-next-line no-underscore-dangle */
        R.propSatisfies(R.lt(R.__, state.editedProfile.allHistoryResults.length), 'showMoreHistorySegment'),
        R.compose(
          /* eslint-disable-next-line no-underscore-dangle */
          R.over(resultsToShow, R.concat(R.__, addSegment)),
          R.evolve({ showMoreHistorySegment: R.inc }),
        ),
      )(state)
    }
    case Actions.PROFILE_EXPAND_CHILDREN: {
      const id = payload
      const idx = R.findIndex(R.propEq('id', id), state.list)
      return R.over(R.lensPath(['list', idx, 'expanded']), R.not, state)
    }
    case Actions.PROFILE_EXPAND_PARENT_TREE: {
      const id = payload

      const parentIds = findParents({ children: state.tree }, id)

      const copiedProfilesList = [...state.list]
      copiedProfilesList.forEach((profile) => {
        if (parentIds.indexOf(profile.id) !== -1) {
          profile.expanded = true
        }
      })

      return { ...state, list: copiedProfilesList }
    }

    default:
      return state
  }
}

export default profilesReducer
