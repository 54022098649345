// @ts-nocheck
import R from 'ramda'
import { createSelector } from 'reselect'
import dayjs from 'dayjs'

import { getArticles as getState, getForm } from './rootSelectors'
import { articleIdToArticle, eqArticles } from '../opoint/articles/index'
import { searchIsFinished } from './searchSelectors'
import type { Tag, Article } from '../opoint/flow'
import { getUISetting } from './settingsSelectors'

export const getArticles = createSelector(getState, (articlesState) => articlesState.list)

export const getEditedArticle = createSelector(getState, (articles) => articles.editedArticle)

export const getEditedArticleXHRStatus = createSelector(getState, (articles) => articles.editedArticleXHRStatus)

export const getEditedArticlePreviewMode = createSelector(getState, (articles) => articles.editedArticlePreviewMode)

export const getArticlesCount = createSelector(getArticles, (articles) => articles.length)

export const getCheckedArticles = createSelector(getState, (articlesState) => articlesState.checked)

export const getShareArticleXHRStatus = createSelector(getState, (articlesState) => articlesState.shareArticleXHRStatus)

export const getShareArticleMessage = createSelector(getState, (articlesState) => articlesState.shareArticleMessage)

export const getEditedArticlesState = createSelector(getState, (articlesState) => articlesState.editedArticleState)

export const getShareArticleAttachmentFlag = createSelector(
  getState,
  (articlesState) => articlesState.shareArticleAttachmentFlag,
)

export const noArticlesFound = createSelector(
  getArticles,
  searchIsFinished,
  (articles, isFinished) => articles.length === 0 && isFinished,
)

export const getSelectedArticlesIds = createSelector(
  getState,
  R.compose(R.map(articleIdToArticle), R.keys, R.prop('checked')),
)

export const getIdentical = createSelector(getState, (articlesState) => articlesState.identical)

// Find article by ID - if you cannot find it in listing, find it in identical articles
export const getArticleById = ({ id_site, id_article }: { id_site: string; id_article: string }) =>
  createSelector(
    getArticles,
    (articleList) =>
      R.find(eqArticles({ id_site, id_article }), articleList) ||
      R.compose(
        R.find(eqArticles({ id_site, id_article })),
        R.unnest,
        R.map((article) => article.identical_documents.document),
      )(articleList),
  )

export const getSelectedArticles = createSelector(R.identity, (state) =>
  R.compose(
    R.map(({ id_site, id_article }) => getArticleById({ id_site, id_article })(state)),
    getSelectedArticlesIds,
  )(state),
)

export const getSelectedArticlesTags = createSelector(getSelectedArticles, R.map(R.pick(['id', 'tags'])))

export const getSelectedArticlesTagCount = (tagId: string) =>
  createSelector(
    getSelectedArticlesTags,
    R.compose(
      R.reduce((acc, [id, article]) => (R.has(tagId, article.tags) ? R.inc(acc) : acc), 0),
      R.toPairs,
    ),
  )

export const getSelectedArticleTagWeight = (tagId: string) =>
  createSelector(
    getSelectedArticlesTags,
    R.compose(R.path([tagId, 'weight']), R.find(R.has(tagId)), R.pluck('tags'), R.values),
  )

export const selectedArticlesHaveSameTagWeight = (tagId: string) =>
  createSelector(
    getSelectedArticlesTags,
    R.compose(
      R.converge(R.equals, [R.always(1), R.compose(R.length, R.uniq, R.map(R.view(R.lensPath([tagId, 'weight']))))]),
      R.filter(R.has(tagId)),
      R.pluck('tags'),
      R.values,
    ),
  )

export const getIdenticalArticles = createSelector(getState, (articlesState) => articlesState.identical)

export const getActive = createSelector(getState, (articlesState) => articlesState.active)

export const getActiveArticleIndex = createSelector(getActive, (activeArticlesState) => activeArticlesState.index)

export const getActiveArticle = createSelector(
  getState,
  (articlesState) => articlesState.list[articlesState.active.index],
)

export const getAddArticleValues = createSelector(getState, (articlesState) => articlesState.addArticleForm)

export const getAddArticleTags = createSelector(getAddArticleValues, (addArticleForm) => addArticleForm.tags)

export const getArticleTagById = ({ id: tagId }: Tag, { id: articleId }: Article) =>
  createSelector(
    getState,
    R.compose(R.path(['tags', Math.abs(tagId)]), R.find(R.propEq('id', articleId)), R.path(['list'])),
  )

export const getArticlesTagById = ({ id: tagId }: Tag, articles: Array<Article>) =>
  createSelector(
    getState,
    R.compose(
      R.map(R.path(['tags', tagId])),

      R.filter(({ id }) =>
        R.contains(
          id,
          R.chain(({ id }) => id, articles),
        ),
      ),
      R.path(['list']),
    ),
  )

export const getArticleTrashTags = createSelector(
  (state) => state.trash.list,
  (trashTags) =>
    R.memoize((article) => {
      const articleTags = Object.keys(article?.tags ?? []).map((t) => parseInt(t, 10))

      return R.intersection(articleTags, R.pluck('id', trashTags))
    }),
)

export const getListLength = createSelector(getState, R.path(['list', 'length']))
