import Rx from 'rxjs'
import R from 'ramda'

import buildAction from '../helpers/buildAction'
import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'
import * as ActionTypes from '../constants/actionTypes'
import { getFolders } from '../opoint/folders'
import { getTags } from '../selectors/tagsSelectors'
import { getProfiles } from '../selectors/profilesSelectors'
import { Folder } from '../opoint/flow'
import { getSortedFolders } from '../selectors/foldersSelectors'

const foldersTree = (folders: Array<Folder>, profiles, tags) => {
  const foldersWithChildren = folders.map((folder) => {
    const items = folder.type === 0 ? profiles : tags
    const filteredItems = items.filter((item) => item.folder === folder.id && item.type === folder.type)

    const parentTree = R.groupBy(R.prop('parent'), filteredItems)
    const rootProfiles = parentTree[0]
    const setChildrenForProfile = (profile) =>
      R.assoc('children', (parentTree[profile.id] || []).map(setChildrenForProfile), profile)

    return {
      ...folder,
      children: rootProfiles ? R.map(setChildrenForProfile)(rootProfiles) : filteredItems,
    }
  })
  return foldersWithChildren
}

const fetchFolderssOnLogIn = (action$: any) =>
  action$.ofType(ActionTypes.LOG_IN_SUCCESS).mapTo(buildAction(ActionTypes.FOLDERS_FETCH))

const profileGroupsFetchEpic = (action$: any) =>
  action$
    .ofType(ActionTypes.FOLDERS_FETCH)
    .switchMap(() =>
      Rx.Observable.fromPromise(getFolders()).map((profileGroups) =>
        buildAction(ActionTypes.FOLDERS_FETCH_SUCCESS, profileGroups),
      ),
    )
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)
    .catch(() => Rx.Observable.of(buildAction(ActionTypes.FOLDERS_FETCH_FAILURE)))

const createGroupsTreeEpic = (action$: any, { getState }: any) =>
  action$
    .ofType(ActionTypes.TAGS_FETCH_SUCCESS)
    .delay(1000)
    .switchMap(() => {
      const state = getState()
      const tagsList = getTags(state)
      const profilesList = getProfiles(state)
      const groupsList = getSortedFolders(state)

      const groupsTreeWithChildren = foldersTree(groupsList, profilesList, tagsList)

      return Rx.Observable.of(buildAction(ActionTypes.CREATE_FOLDERS_TREE_SUCCESS, groupsTreeWithChildren))
    })
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)

export default [fetchFolderssOnLogIn, profileGroupsFetchEpic, createGroupsTreeEpic]
