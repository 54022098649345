import Rx from 'rxjs'

import * as ActionTypes from '../../constants/actionTypes'
import { cannotDeleteEntityReason } from '../../opoint/common/index'
import { error, success } from '../../new-components/common/Toasts'

const alertError = (payload, alertId) => {
  if (payload.responseErrors) {
    Object.values(payload.responseErrors).forEach(
      (errors) =>
        Array.isArray(errors) &&
        errors.forEach((error) =>
          error(error, {
            toastId: `${alertId}`,
          }),
        ),
    )
  } else {
    error(payload.message, {
      toastId: `${alertId}`,
    })
  }
}

const contactErrorEpic = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type, payload }) => {
    switch (type) {
      case ActionTypes.ADD_ARTICLE_SUCCESS:
        success(i18n.t('Article was successfully saved, and it`s ID is copied to clipboard'), {
          toastId: 'ADD_ARTICLE_SUCCESS',
        })
        break
      case ActionTypes.EDIT_ECB_ARTICLE_SUCCESS:
        success(i18n.t('Article was successfully updated.'), {
          toastId: 'EDIT_ECB_ARTICLE_SUCCESS',
        })
        break
      case ActionTypes.CONTACT_SAVE_SUCCESS:
        success(i18n.t('Contact was successfully saved.'), {
          toastId: 'CONTACT_SAVE_SUCCESS',
        })
        break
      case ActionTypes.CONTACT_DELETE_SUCCESS:
        success(i18n.t('Contact was successfully deleted'), {
          toastId: 'CONTACT_DELETE_SUCCESS',
        })
        break
      case ActionTypes.GROUP_DELETE_SUCCESS:
        success(i18n.t('Group was successfully deleted'), {
          toastId: 'GROUP_DELETE_SUCCESS',
        })
        break
      case ActionTypes.GROUP_SAVE_SUCCESS:
        success(i18n.t('Group was successfully saved.'), {
          toastId: 'GROUP_SAVE_SUCCESS',
        })
        break
      case ActionTypes.CONTACT_SAVE_ERROR:
      case ActionTypes.GROUP_SAVE_ERROR:
        alertError(payload, type === ActionTypes.CONTACT_SAVE_ERROR ? 'CONTACT_SAVE_ERROR' : 'GROUP_SAVE_ERROR')
        break
      case ActionTypes.CONTACT_INACTIVE_RESET_SUCCESS:
        success(i18n.t('Contact alerts pause is now not active.'), {
          toastId: 'CONTACT_INACTIVE_RESET_SUCCESS',
        })
        break
      case ActionTypes.CONTACT_INACTIVE_RESET_ERROR:
        error(payload.message, {
          toastId: 'CONTACT_INACTIVE_RESET_ERROR',
        })
        break
      case ActionTypes.CONTACT_DELETE_ERROR:
        error(payload.message, {
          toastId: 'CONTACT_DELETE_ERROR',
        })
        break
      case ActionTypes.CONTACT_USED_DELETE_ERROR:
        error(payload.message, {
          toastId: 'CONTACT_USED_DELETE_ERROR',
        })
        break
      case ActionTypes.GROUP_DELETE_ERROR:
        error(payload.message, {
          toastId: 'GROUP_DELETE_ERROR',
        })
        break
      case ActionTypes.CONTACT_SET_ACTIVE_FAILURE:
        error(i18n.t('We are currently not able to select this contact'), {
          toastId: 'CONTACT_SET_ACTIVE_FAILURE',
        })
        break
      case ActionTypes.GROUP_SET_ACTIVE_FAILURE:
        error(i18n.t('We are currently not able to select this group'), {
          toastId: 'GROUP_SET_ACTIVE_FAILURE',
        })
        break
      default:
    }
    return Rx.Observable.of()
  })

const alertErrorEpic = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type, payload }) => {
    switch (type) {
      case ActionTypes.ALERT_DELETE_SUCCESS:
        success(i18n.t('Alert was successfully deleted'), {
          toastId: 'ALERT_DELETE_SUCCESS',
        })
        break
      case ActionTypes.SAVE_ALERT_SUCCESS:
        success(i18n.t('Alert was successfully saved'), {
          toastId: 'SAVE_ALERT_SUCCESSS',
        })
        break
      case ActionTypes.ALERT_SEND_SUCCESS:
        success(i18n.t('Alert was successfully sent'), {
          toastId: 'ALERT_SEND_SUCCESSS',
        })
        break
      case ActionTypes.ALERT_HISTORY_SEND_AGAIN_SUCCESS:
        success(i18n.t('Sent alert was successfully resent'), {
          toastId: 'ALERT_HISTORY_SEND_AGAIN_SUCCESS',
        })
        break
      case ActionTypes.ALERT_REMOVE_TRASHED_ARTICLES_SUCCESS:
        success(i18n.t('Articles were successfully removed'), {
          toastId: 'ALERT_REMOVE_TRASHED_ARTICLES_SUCCESS',
        })
        break
      case ActionTypes.GO_TO_ALERT_FAILURE:
        error(i18n.t('We were unable to go to selected alert'), {
          toastId: 'GO_TO_ALERT_FAILURE',
        })
        break
      case ActionTypes.ALERT_DELETE_FAILURE:
        error(i18n.t('We were unable to delete selected alert'), {
          toastId: 'ALERT_DELETE_FAILURE',
        })
        break
      case ActionTypes.CREATE_ALERT_FAILURE:
        error(i18n.t('We were unable to go to the creation of a new alert'), {
          toastId: 'CREATE_ALERT_FAILURE',
        })
        break
      case ActionTypes.SAVE_ALERT_FAILURE:
        error(i18n.t('We were unable to save this alert'), {
          toastId: 'SAVE_ALERT_FAILURES',
        })
        break
      case ActionTypes.ALERT_SEND_FAILURE:
        error(i18n.t('We were unable to send this alert'), {
          toastId: 'ALERT_SEND_FAILURES',
        })
        break
      case ActionTypes.ALERT_REMOVE_TRASHED_ARTICLES_FAILURE:
        error(i18n.t('We were unable to delete selected articles'), {
          toastId: 'ALERT_REMOVE_TRASHED_ARTICLES_FAILURE',
        })
        break
      case ActionTypes.ALERT_FETCH_FAILURE:
        error(i18n.t('We were unable to fetch this alert'), {
          toastId: 'ALERT_FETCH_FAILURE',
        })
        break
      case ActionTypes.ALERT_FETCH_NEXT_PREVIEW_FAILURE:
        error(i18n.t('We were unable to fetch preview of next alert'), {
          toastId: 'ALERT_FETCH_NEXT_PREVIEW_FAILURE',
        })
        break
      // TBD
      case ActionTypes.ALERT_FETCH_NEXT_FAILURE:
        error(i18n.t('We were unable to fetch this alert'), {
          toastId: 'ALERT_FETCH_NEXT_FAILURE',
        })
        break
      case ActionTypes.ALERT_FETCH_HISTORY_FAILURE:
        error(i18n.t('We were unable to fetch alert history'), {
          toastId: 'ALERT_FETCH_HISTORY_FAILURE',
        })
        break
      case ActionTypes.ALERT_FETCH_HISTORY_ITEM_FAILURE:
        error(i18n.t('We were unable to fetch alert history details'), {
          toastId: 'ALERT_FETCH_HISTORY_ITEM_FAILURE',
        })
        break
      case ActionTypes.ALERT_TEMPLATE_FETCH_FAILURE:
        error(i18n.t('We were unable to get this preview'), {
          toastId: 'ALERT_TEMPLATE_FETCH_FAILURE',
        })
        break
      case ActionTypes.SAVE_ALERT_VALID_FAILURE:
      case ActionTypes.LEAVE_ALERT_FAILURE:
        error(payload.error, {
          toastId: type === ActionTypes.SAVE_ALERT_VALID_FAILURE ? 'SAVE_ALERT_VALID_FAILURE' : 'LEAVE_ALERT_FAILURE',
        })
        break
      default:
    }
    return Rx.Observable.of()
  })

const articlesErrorEpic = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type }) => {
    switch (type) {
      case ActionTypes.EDIT_ARTICLE_SUCCESS:
        success(i18n.t('Article was successfully updated'), {
          toastId: 'EDIT_ARTICLE_SUCCESS',
        })
        break
      case ActionTypes.SHARE_ARTICLES_SUCCESS:
        success(i18n.t('Article was successfully shared'), {
          toastId: 'SHARE_ARTICLES_SUCCESS',
        })
        break
      case ActionTypes.EDIT_ARTICLE_FAILURE:
        error(i18n.t("Article couldn't be updated, please contact support"), {
          toastId: 'EDIT_ARTICLE_FAILURE',
        })
        break
      case ActionTypes.ADD_ARTICLE_SUGGESTIONS_FAILURE:
        error(i18n.t('We were unable to get suggestions'), {
          toastId: 'ADD_ARTICLE_SUGGESTIONS_FAILURE',
        })
        break
      case ActionTypes.ADD_ARTICLE_SUGGESTIONS_COUNTRY_FAILURE:
        error(i18n.t('We were unable to get suggestions'), {
          toastId: 'ADD_ARTICLE_SUGGESTIONS_COUNTRY_FAILURE',
        })
        break
      case ActionTypes.ADD_ARTICLE_FAILURE:
        error(i18n.t('We were unable to add this article'), {
          toastId: 'ADD_ARTICLE_FAILURE',
        })
        break
      case ActionTypes.ADD_ARTICLE_FILE_UPLOAD_FAILURE:
        error(i18n.t('We were unable to upload this file'), {
          toastId: 'ADD_ARTICLE_FILE_UPLOAD_FAILURE',
        })
        break
      case ActionTypes.SHARE_ARTICLES_FAILURE:
        error(i18n.t('Error while sharing an article'), {
          toastId: 'SHARE_ARTICLES_FAILURE',
        })
        break
      case ActionTypes.ADD_ARTICLE_FILE_UNSUPPORTED_FILE_ERROR:
        error(i18n.t("Files this kind of type can't be uploaded"), {
          toastId: 'ADD_ARTICLE_FILE_UNSUPPORTED_FILE_ERROR',
        })
        break
      case ActionTypes.REPORT_ISSUE_FAILURE:
        error(i18n.t('We were unable to report your issue'), {
          toastId: 'REPORT_ISSUE_FAILURE',
        })
        break
      case ActionTypes.REQUEST_ACTION_FAILURE:
        error(i18n.t('We were unable to send your request'), {
          toastId: 'REQUEST_ACTION_FAILURE',
        })
        break
      default:
    }
    return Rx.Observable.of()
  })

const feedErrorEpic = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type, payload }) => {
    switch (type) {
      case ActionTypes.FEED_SAVE_SUCCESS:
        success(i18n.t('Feed successfully saved'), {
          toastId: 'FEED_SAVE_SUCCESS',
        })
        break
      case ActionTypes.FEED_NEW_SUCCESS:
        success(i18n.t('New feed successfully created'), {
          toastId: 'FEED_NEW_SUCCESS',
        })
        break
      case ActionTypes.FEED_SET_ACTIVE_FAILURE:
        error(i18n.t('We were unable to select this feed'), {
          toastId: 'FEED_SET_ACTIVE_FAILURE',
        })
        break
      case ActionTypes.FEED_SAVE_ERROR:
        error(i18n.t('We were unable to save this feed'), {
          toastId: 'FEED_SAVE_ERROR',
        })
        break
      case ActionTypes.FEED_NEW_ERROR:
        error(i18n.t('We were not able to create new feed'), {
          toastId: 'FEED_NEW_ERROR',
        })
        break
      case ActionTypes.FEED_DELETE_ERROR:
        error(i18n.t('We were unable to delete this feed'), {
          toastId: 'FEED_DELETE_ERROR',
        })
        break
      case ActionTypes.SAVE_FEED_VALID_FAILURE:
        error(payload.error, {
          toastId: 'SAVE_FEED_VALID_FAILURE',
        })
        break
      default:
    }
    return Rx.Observable.of()
  })

const notificationsEpics = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type }) => {
    switch (type) {
      case ActionTypes.NOTIFICATIONS_FETCH_FAILURE:
        error(i18n.t('We were unable to fetch notifications'), {
          toastId: 'NOTIFICATIONS_FETCH_FAILURE',
        })
        break
      default:
    }
    return Rx.Observable.of()
  })
const profilesErrorEpics = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type }) => {
    switch (type) {
      case ActionTypes.PROFILES_FETCH_FAILURE:
        error(i18n.t('We were unable to fetch profiles'), {
          toastId: 'PROFILES_FETCH_FAILURE',
        })
        break
      case ActionTypes.FOLDERS_FETCH_FAILURE:
        error(i18n.t('We were unable to fetch folders'), {
          toastId: 'FOLDERS_FETCH_FAILURE',
        })
        break
      case ActionTypes.PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_FAILURE:
        error(i18n.t('We were unable to provide suggestions'), {
          toastId: 'PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_FAILURE',
        })
        break
      case ActionTypes.PROFILE_EDITOR_FILTERS_FETCH_SIMPLE_FAILURE:
        error(i18n.t('We were unable to provide suggestions'), {
          toastId: 'PROFILE_EDITOR_FILTERS_FETCH_SIMPLE_FAILURE',
        })
        break
      default:
    }
    return Rx.Observable.of()
  })

const profileEditorErrorEpics = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type, payload }) => {
    switch (type) {
      case ActionTypes.SAVE_AS_PROFILE_SUCCESS:
      case ActionTypes.PROFILE_EDITOR_SAVE_PROFILE_SUCCESS:
        success(i18n.t('Profile was successfully saved'), {
          toastId: 'PROFILE_EDITOR_SAVE_PROFILE_SUCCESS',
        })
        break
      case ActionTypes.PROFILE_DELETE_SUCCESS:
        success(i18n.t('Profile was successfully deleted'), {
          toastId: 'PROFILE_DELETE_SUCCESS',
        })
        break
      case ActionTypes.PROFILE_EDITOR_SAVE_PROFILE_FAILURE:
        error(i18n.t('We were unable to save this profile'), {
          toastId: 'PROFILE_EDITOR_SAVE_PROFILE_FAILURE',
        })
        break
      case ActionTypes.LOAD_EDITED_PROFILE_FAILURE:
        error(i18n.t('We were unable to load this profile'), {
          toastId: 'LOAD_EDITED_PROFILE_FAILURE',
        })
        break
      case ActionTypes.PROFILE_DELETE_FAILURE:
        error(cannotDeleteEntityReason(payload, i18n), {
          toastId: 'PROFILE_DELETE_FAILURE',
        })
        break
      case ActionTypes.PROFILE_HISTORY_FETCH_FAILURE:
        error(i18n.t('We were unable to fetch this profile history'), {
          toastId: 'PROFILE_HISTORY_FETCH_FAILURE',
        })
        break
      case ActionTypes.DELETED_PROFILES_HISTORY_FETCH_FAILURE:
        error(i18n.t('We were unable to fetch deleted profiles history'), {
          toastId: 'DELETED_PROFILES_HISTORY_FETCH_FAILURE',
        })
        break
      case ActionTypes.PROFILE_EDITOR_INVALID_SEARCHLINE:
        error(i18n.t('We were unable to save this profile - search line is in wrong format'), {
          toastId: 'PROFILE_EDITOR_INVALID_SEARCHLINE',
        })
        break
      default:
    }
    return Rx.Observable.of()
  })

const reportErrorEpics = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type, payload }) => {
    switch (type) {
      case ActionTypes.REPORTS_SHARE_SUCCESS:
        success(i18n.t('We successfully shared this report'), {
          toastId: 'REPORTS_SHARE_SUCCESS',
        })
        break
      case ActionTypes.DELETE_REPORT_NOTIFICATION_SUCCESS:
        success(i18n.t('We successfully deleted selected report notification'), {
          toastId: 'DELETE_REPORT_NOTIFICATION_SUCCESS',
        })
        break
      case ActionTypes.DELETE_REPORT_SUCCESS:
        success(i18n.t('We successfully deleted selected report'), {
          toastId: 'DELETE_REPORT_SUCCESS',
        })
        break
      case ActionTypes.REPORTS_SHARE_FAILURE:
        error(i18n.t('We were not able to share this report'), {
          toastId: 'REPORTS_SHARE_FAILURE',
        })
        break
      case ActionTypes.DELETE_REPORT_NOTIFICATION_FAILURE:
        error(i18n.t('We were not able to delete selected report notification'), {
          toastId: 'DELETE_REPORT_NOTIFICATION_FAILURE',
        })
        break
      case ActionTypes.DELETE_REPORT_FAILURE:
        error(i18n.t('We were not able to delete selected report'), {
          toastId: 'DELETE_REPORT_FAILURE',
        })
        break
      case ActionTypes.REPORTS_ARTICLES_FAILURE:
        error(i18n.t('We were not able to fetch articles for report'), {
          toastId: 'REPORTS_ARTICLES_FAILURE',
        })
        break
      case ActionTypes.REPORTS_HISTORY_FETCH_FAILURE:
        error(i18n.t('We were not able to fetch report history'), {
          toastId: 'REPORTS_HISTORY_FETCH_FAILURE',
        })
        break
      case ActionTypes.REPORTHISTORY_FETCH_FAILURE:
        error(i18n.t('We were not able to fetch report history'), {
          toastId: 'REPORTHISTORY_FETCH_FAILURE',
        })
        break
      case ActionTypes.REPORTS_VALID_SHARE_FAILURE:
        error(payload.error, {
          toastId: 'REPORTS_VALID_SHARE_FAILURE',
        })
        break
      default:
    }
    return Rx.Observable.of()
  })

const searchErrorEpics = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type }) => {
    switch (type) {
      case ActionTypes.FETCH_ARTICLES_FAILURE:
        error(i18n.t('We were not able to fetch articles for this search'), {
          toastId: 'FETCH_ARTICLES_FAILURE',
        })
        break
      case ActionTypes.SEARCH_CHANGE_DATERANGE_FAILURE:
        error(i18n.t('We were not able to change date range'), {
          toastId: 'SEARCH_CHANGE_DATERANGE_FAILURE',
        })
        break
      case ActionTypes.FETCH_FILTER_DETAIL_FAILURE:
        error(i18n.t('We were not able to fetch filter details'), {
          toastId: 'FETCH_FILTER_DETAIL_FAILURE',
        })
        break
      default:
    }
    return Rx.Observable.of()
  })

const statisticsErrorEpics = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type }) => {
    switch (type) {
      case ActionTypes.FETCH_STATISTICS_FAILURE:
        error(i18n.t('We were not able to fetch statistics for this search'), {
          toastId: 'FETCH_STATISTICS_FAILURE',
        })
        break
      case ActionTypes.STATISTICS_VIEW_DELETE_SUCCESS:
        success(i18n.t('Statistic view was successfully deleted'), {
          toastId: 'STATISTICS_VIEW_DELETE_SUCCESS',
        })
        break
      case ActionTypes.STATISTICS_VIEW_DELETE_FAILURE:
        error(i18n.t('We were unable to delete selected statistic view'), {
          toastId: 'STATISTICS_VIEW_DELETE_FAILURE',
        })
        break
      case ActionTypes.STATISTICS_VIEW_SAVE_SUCCESS:
        success(i18n.t('Statistic view was successfully saved'), {
          toastId: 'STATISTICS_VIEW_SAVE_SUCCESS',
        })
        break
      case ActionTypes.STATISTICS_VIEW_SAVE_FAILURE:
        error(i18n.t('We were unable to save selected statistic view'), {
          toastId: 'STATISTICS_VIEW_SAVE_FAILURE',
        })
        break
      case ActionTypes.STATISTICS_VIEW_EXPORT_PDF_SUCCESS:
        success(i18n.t('Statistics view PDF was successfully generated and downloaded'), {
          toastId: 'STATISTICS_VIEW_EXPORT_PDF_SUCCESS',
        })
        break
      case ActionTypes.STATISTICS_VIEW_EXPORT_PDF_FAILURE:
        error(i18n.t('Export statistics as PDF failed'), {
          toastId: 'STATISTICS_VIEW_EXPORT_PDF_FAILURE',
        })
        break
      case ActionTypes.GO_TO_COMPARISON_FAILURE:
        error(i18n.t('We were not able to compare periods'))
        break
      default:
    }
    return Rx.Observable.of()
  })

const tagErrorEpics = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type, payload }) => {
    switch (type) {
      case ActionTypes.ADD_TAG_SUCCESS:
        success(i18n.t('We successfully added new tag'), {
          toastId: 'ADD_TAG_SUCCESS',
        })
        break
      case ActionTypes.DELETE_TAG_SUCCESS:
        success(i18n.t('We successfully deleted selected tag'), {
          toastId: 'DELETE_TAG_SUCCESS',
        })
        break
      case ActionTypes.TAG_ARTICLES_FAILURE:
        error(i18n.t('We were not able to tag this article'), {
          toastId: 'TAG_ARTICLES_FAILURE',
        })
        break
      case ActionTypes.UNTAG_ARTICLES_FAILURE:
        error(i18n.t('We were not able to untag this article'), {
          toastId: 'UNTAG_ARTICLES_FAILURE',
        })
        break
      case ActionTypes.DELETE_ARTICLES_FAILURE:
        error(i18n.t('We were not able to delete this article'), {
          toastId: 'DELETE_ARTICLES_FAILURE',
        })
        break
      case ActionTypes.UNDELETE_ARTICLES_FAILURE:
        error(i18n.t('We were not able to restore this article'), {
          toastId: 'UNDELETE_ARTICLES_FAILURE',
        })
        break
      case ActionTypes.ADD_TAG_FAILURE:
        error(i18n.t('We were not able to add this tag'), {
          toastId: 'ADD_TAG_FAILURE',
        })
        break
      case ActionTypes.EDIT_TAG_FAILURE:
        error(i18n.t('We were not able to edit this tag'), {
          toastId: 'EDIT_TAG_FAILURE',
        })
        break
      case ActionTypes.DELETE_TAG_FAILURE:
        error(cannotDeleteEntityReason(payload, i18n), {
          toastId: 'DELETE_TAG_FAILURE',
        })
        break
      default:
    }
    return Rx.Observable.of()
  })

const templateErrorEpics = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type, payload }) => {
    switch (type) {
      case ActionTypes.TEMPLATE_SAVE_SUCCESS:
        success(i18n.t('We successfully saved your template'), {
          toastId: 'TEMPLATE_SAVE_SUCCESS',
        })
        break
      case ActionTypes.TEMPLATE_NEW_SUCCESS:
        success(i18n.t('We successfully created new template'), {
          toastId: 'TEMPLATE_NEW_SUCCESS',
        })
        break
      case ActionTypes.TEMPLATE_DELETE_SUCCESS:
        success(i18n.t('We successfully deleted template'), {
          toastId: 'TEMPLATE_DELETE_SUCCESS',
        })
        break
      case ActionTypes.TEMPLATE_SAVE_FAILURE:
        error(i18n.t('We were not able to save your template'), {
          toastId: 'TEMPLATE_SAVE_FAILURE',
        })
        break
      case ActionTypes.TEMPLATE_NEW_FAILURE:
        error(i18n.t('We were not able to create new template'), {
          toastId: 'TEMPLATE_NEW_FAILURE',
        })
        break
      case ActionTypes.TEMPLATE_DELETE_FAILURE:
        error(i18n.t('We were not able to delete your template'), {
          toastId: 'TEMPLATE_DELETE_FAILURE',
        })
        break
      default:
    }
    return Rx.Observable.of()
  })

const routerErrorEpic = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type }) => {
    switch (type) {
      case ActionTypes.ROUTE_CHANGE_FAILURE:
        error(i18n.t('We are unable to change route, try again'), {
          toastId: 'ROUTE_CHANGE_FAILURE',
        })
        break
      default:
    }
    return Rx.Observable.of()
  })

const portalErrorEpic = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type }) => {
    switch (type) {
      case ActionTypes.TOKEN_EXPIRED:
        error(i18n.t('Authentication token expired, please log in again.'), {
          toastId: 'TOKEN_EXPIRED',
        })
        break
      case ActionTypes.SERVER_NOT_RESPONDING:
        error(i18n.t('We are sorry, server is not responding, please, try again later.'), {
          toastId: 'SERVER_NOT_RESPONDING',
        })
        break
      default:
    }
    return Rx.Observable.of()
  })

export default [
  alertErrorEpic,
  articlesErrorEpic,
  contactErrorEpic,
  feedErrorEpic,
  notificationsEpics,
  portalErrorEpic,
  profileEditorErrorEpics,
  profilesErrorEpics,
  reportErrorEpics,
  routerErrorEpic,
  searchErrorEpics,
  statisticsErrorEpics,
  tagErrorEpics,
  templateErrorEpics,
]
