// @ts-nocheck
import Rx from 'rxjs'
import { EmptyObservable } from 'rxjs/observable/EmptyObservable'

import * as ActionTypes from '../constants/actionTypes'
import { getMultipleSuggestions, getSuggestionOfType } from '../opoint/search'
import buildAction from '../helpers/buildAction'
import { getActiveArticle } from '../selectors/articlesSelectors'
import {
  addArticle,
  shareArticles,
  updateArticle,
  uploadArticleFile,
  getGroupsOfIdMediatypes,
  suggestionMediatypesIntoSuggestionShape,
  sendPaymentInfo,
} from '../opoint/articles'
import {
  getArticleListingStyle,
  getDefaultSearch,
  getSuggestionsLocale,
  getFilterSuggest,
} from '../selectors/settingsSelectors'
import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'
import type { AddArticleSuggestionMediatype, AddArticleSuggestionMediatypeInSuggestionShape } from '../opoint/flow'
import { LISTING_STYLES } from '../opoint/settings'

const addArticleSourceEpic = (action$, { getState }) => {
  const SUGGESTION_ACTIONS = {
    site: {
      failure: ActionTypes.ADD_ARTICLE_SUGGESTIONS_FAILURE,
      success: ActionTypes.ADD_ARTICLE_SUGGESTIONS_SUCCESS,
    },
    lang: {
      failure: ActionTypes.ADD_ARTICLE_SUGGESTIONS_LANG_FAILURE,
      success: ActionTypes.ADD_ARTICLE_SUGGESTIONS_LANG_SUCCESS,
    },
    geo: {
      failure: ActionTypes.ADD_ARTICLE_SUGGESTIONS_COUNTRY_FAILURE,
      success: ActionTypes.ADD_ARTICLE_SUGGESTIONS_COUNTRY_SUCCESS,
    },
  }

  return action$
    .ofType(ActionTypes.ADD_ARTICLE_TRIGGER_SUGGESTION)
    .mergeMap(({ payload: { searchterm, suggestionType, filters } }) => {
      const state = getState()
      const suggestionsLocale = getSuggestionsLocale(state)
      const defaultSearchScope = getDefaultSearch(state)
      const filterSuggestParameter = getFilterSuggest(state)

      const searchFilters = suggestionType === 'site' && filters
      // Set geo and lang to 30 to get all countries/languages so that we don't overload both the DOM and the suggestion server.
      // It's better to load part of the suggestions at a time then load all the possible suggestions
      const NUMBER_OF_SUGGESTIONS = ['geo', 'lang'].includes(suggestionType) ? 30 : 10

      /**
       * The filter suggest parameter is set to 196 since this is the only suggestion filters we are interested in.
       * 196 (bit flag) means that lang, geo, sites that are enabled
       * In other terms it reflects as this in APP_UI_SETTINGS:
       * - NEW_PORTAL_FILTERS: "timeperiod=false,geo=true,lang=true,media=false,content=false,site=true,rank=false,cov=false,profiles=false"
       * - NEW_PORTAL_FILTER_SUGGEST: 196
       */

      const suggestParameter = 196

      return Rx.Observable.from(
        getMultipleSuggestions(
          searchterm,
          searchFilters,
          suggestionsLocale,
          defaultSearchScope,
          NUMBER_OF_SUGGESTIONS,
          suggestParameter, // See comment above 👆
        ),
      )
        .map((data) =>
          buildAction(SUGGESTION_ACTIONS[suggestionType].success, {
            suggestions: getSuggestionOfType(suggestionType, data),
            suggestionType,
          }),
        )
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() => Rx.Observable.of(buildAction(SUGGESTION_ACTIONS[suggestionType].failure)))
    })
}

const addArticleEpic = (action$, { getState }) =>
  action$.ofType(ActionTypes.ADD_ARTICLE).mergeMap(({ payload: { newArticle } }) => {
    return Rx.Observable.from(addArticle(newArticle, 'user'))
      .map((data) => buildAction(ActionTypes.ADD_ARTICLE_SUCCESS, data))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.ADD_ARTICLE_FAILURE)))
  })

const uploadArticleFileEpic = (action$, { getState }) =>
  action$.ofType(ActionTypes.ADD_ARTICLE_FILE_UPLOAD).mergeMap(({ payload: { file, onProcessEndedCallback } }) =>
    Rx.Observable.from(uploadArticleFile(file))
      .map((data) => {
        onProcessEndedCallback()
        return buildAction(ActionTypes.ADD_ARTICLE_FILE_UPLOAD_SUCCESS, {
          data,
          original: file,
        })
      })
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => {
        onProcessEndedCallback()
        return Rx.Observable.of(buildAction(ActionTypes.ADD_ARTICLE_FILE_UPLOAD_FAILURE))
      }),
  )

const updateArticleEpic = (action$) =>
  action$.ofType(ActionTypes.EDIT_ARTICLE).switchMap(({ payload: { article, updatedArticleData } }) =>
    Rx.Observable.fromPromise(updateArticle(article, updatedArticleData))

      .map((article) => buildAction(ActionTypes.EDIT_ARTICLE_SUCCESS, { article }))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() =>
        Rx.Observable.of(
          buildAction(ActionTypes.EDIT_ARTICLE_FAILURE, {
            article,
            updatedArticleData,
          }),
        ),
      ),
  )

const shareArticleEpic = (action$) =>
  action$
    .ofType(ActionTypes.SHARE_ARTICLES)
    .switchMap(({ payload: { articles, message, recipients, shareAttachment, title, templateId } }) => {
      const apiRecipients = recipients.map((contact) => {
        const { type, id } = contact
        return type !== 'email' ? contact : { type, value: id }
      })
      return Rx.Observable.fromPromise(
        shareArticles(articles, message, apiRecipients, shareAttachment, title, templateId),
      )
        .map(() => buildAction(ActionTypes.SHARE_ARTICLES_SUCCESS))
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() => Rx.Observable.of(buildAction(ActionTypes.SHARE_ARTICLES_FAILURE)))
    })

const openModalEpic = (actions$, { getState }) =>
  actions$.ofType(ActionTypes.ADD_ARTICLE_MODAL_OPEN).map(() => buildAction(ActionTypes.ADD_ARTICLE_INIT))

const getGroupsOfIdMediatypesEpic = (actions$) =>
  actions$.ofType(ActionTypes.GET_GROUPS_MEDIATYPES).switchMap(({ payload: { suggsIds } }) =>
    Rx.Observable.fromPromise(getGroupsOfIdMediatypes(suggsIds))
      .map((types: AddArticleSuggestionMediatype) => {
        const mediatypesInSuggestionShape: AddArticleSuggestionMediatypeInSuggestionShape =
          suggestionMediatypesIntoSuggestionShape(types)

        return buildAction(ActionTypes.ADD_ARTICLE_SUGGESTIONS_SUCCESS, {
          suggestions: mediatypesInSuggestionShape,
        })
      })
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown),
  )

const getGroupsOfCountryIdMediatypesEpic = (actions$) =>
  actions$.ofType(ActionTypes.GET_GROUPS_COUNTRY_MEDIATYPES).switchMap(({ payload: { suggsIds } }) =>
    Rx.Observable.fromPromise(getGroupsOfIdMediatypes(suggsIds))
      .map((types: AddArticleSuggestionMediatype) => {
        const mediatypesInSuggestionShape: AddArticleSuggestionMediatypeInSuggestionShape =
          suggestionMediatypesIntoSuggestionShape(types)

        return buildAction(ActionTypes.ADD_ARTICLE_SUGGESTIONS_COUNTRY_SUCCESS, {
          suggestions: mediatypesInSuggestionShape,
        })
      })
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown),
  )

const getGroupsOfLangIdMediatypesEpic = (actions$) =>
  actions$.ofType(ActionTypes.GET_GROUPS_LANG_MEDIATYPES).switchMap(({ payload: { suggsIds } }) =>
    Rx.Observable.fromPromise(getGroupsOfIdMediatypes(suggsIds))
      .map((types: AddArticleSuggestionMediatype) => {
        const mediatypesInSuggestionShape: AddArticleSuggestionMediatypeInSuggestionShape =
          suggestionMediatypesIntoSuggestionShape(types)

        return buildAction(ActionTypes.ADD_ARTICLE_SUGGESTIONS_LANG_SUCCESS, {
          suggestions: mediatypesInSuggestionShape,
        })
      })
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown),
  )

const retrieveInfoAboutArticlePaymentEpic = (actions$, { getState }) =>
  actions$.ofType(ActionTypes.SET_ACTIVE_ARTICLE).switchMap(({ payload }) => {
    const TIME_OFFSET = 10000

    const state = getState()
    const listingStyle: number = getArticleListingStyle(state)
    const activeArticle = getActiveArticle(state)

    if (listingStyle === LISTING_STYLES.ARCHIVE_LEFT || listingStyle === LISTING_STYLES.ARCHIVE_RIGHT) {
      return Rx.Observable.of(
        buildAction(ActionTypes.SEND_ARTICLE_PAYMENT_INFO, {
          article: activeArticle,
          listingStyle,
          timeOffset: 0,
        }),
      )
    }

    return Rx.Observable.timer(TIME_OFFSET)
      .take(1)
      .switchMap(() =>
        Rx.Observable.of(
          buildAction(ActionTypes.SEND_ARTICLE_PAYMENT_INFO, {
            article: activeArticle,
            listingStyle,
            timeOffset: TIME_OFFSET,
          }),
        ),
      )
      .takeUntil(actions$.ofType(ActionTypes.SET_ACTIVE_ARTICLE))
  })

const sendPaymentInfoRequestEpic = (actions$, { getState }) =>
  actions$
    .ofType(ActionTypes.SEND_ARTICLE_PAYMENT_INFO)
    .switchMap(({ payload: { article, listingStyle, timeOffset } }) =>
      article
        ? Rx.Observable.fromPromise(sendPaymentInfo(article, listingStyle, timeOffset))
            .mapTo({
              type: ActionTypes.SEND_ARTICLE_PAYMENT_INFO_SUCCESS,
            })
            .catch(logOutOnExpiredToken)
            .catch(() => Rx.Observable.of())
        : new EmptyObservable(),
    )

export default [
  addArticleEpic,
  addArticleSourceEpic,
  getGroupsOfCountryIdMediatypesEpic,
  getGroupsOfIdMediatypesEpic,
  getGroupsOfLangIdMediatypesEpic,
  openModalEpic,
  shareArticleEpic,
  updateArticleEpic,
  uploadArticleFileEpic,
  retrieveInfoAboutArticlePaymentEpic,
  sendPaymentInfoRequestEpic,
]
