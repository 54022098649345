import Rx from 'rx-dom'
import R from 'ramda'

import config from '../common/config'
import type { Folder } from '../flow'

/**
 * Retrieves folders from API and transforms them into a Promise.
 * @returns {*}
 */
export function getFolders(): Promise<Array<Folder>> {
  // Query on how to fetch specific type and if the folders are owned or not
  // /folders/?type=0&owner=true

  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: config.url.api('/folders/'),
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function createNewFolder(newFolder) {
  const method = 'POST'
  // Handle shares
  const shares = []

  const body = {
    name: newFolder.name,
    type: newFolder.type,
    shares: [],
  }

  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    method,
    url: config.url.api('/folders/'),
    body: JSON.stringify(body),
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function updateFolder(existingFolder, updates) {
  const id = existingFolder.id
  const method = 'PATCH'

  const body = updates

  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    method,
    url: config.url.api(`/folders/${id}/`),
    body: JSON.stringify(body),
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function getSpecificFolder(existingFolder) {
  const id = existingFolder.id

  const requestHeaders = R.merge(config.request.getRequestHeaders(), {
    url: config.url.api(`/folders/${id}/`),
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}
