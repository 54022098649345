import R from 'ramda'

import { Action, Folder } from '../opoint/flow'
import * as Actions from '../constants/actionTypes'
import { getFolderNameAndIcon } from '../new-components/helpers/common'

type FoldersState = {
  folders: Array<Folder>
  foldersTree: any[]
}

export const initialState: FoldersState = {
  folders: [],
  foldersTree: [],
}

const foldersReducer = (state: FoldersState = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    case Actions.FOLDERS_FETCH_SUCCESS: {
      const hasNonDefaultFolders = payload.filter((folder) => folder.category !== 0).length > 0

      payload.forEach((folder) => {
        const { name, icon } = getFolderNameAndIcon(folder, hasNonDefaultFolders)
        folder.name = name
        folder.icon = icon
      })

      return R.assoc('folders', payload)(state)
    }

    case Actions.CREATE_FOLDERS_TREE_SUCCESS: {
      return R.assoc('foldersTree', payload)(state)
    }

    default:
      return state
  }
}

export default foldersReducer
