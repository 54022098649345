import dayjs from 'dayjs'
import * as R from 'ramda'

import OpointDate from '../opoint/common/time'
import { BROADCAST_MEDIA_TYPES } from '../opoint/common/constants'
import { getUserPermissions } from '../selectors/settingsSelectors'
import store from '../store'
import i18n from '../i18nextInit'
import type { Modules, Options } from '../new-components/constants/permissions'

export const returnFirst = (a) => a

export function emptyFunction() {}

export const trimPostLastDotSubsctring = (fileName) => fileName.replace(/\.[^/.]+$/, '')

/**
 * Sorts an array of object by a single criteria
 * @param arr
 * @param sortBy
 * @param toLowerCase
 * @returns
 */
export const simpleSort = (arr, sortBy, toLowerCase?: boolean) => {
  return arr.sort((a, b) => {
    let newA = a
    let newB = b

    if (toLowerCase) {
      newA = a.name.toLowerCase()
      newB = b.name.toLowerCase()
    }

    if (newA[sortBy] < newB[sortBy]) {
      return -1
    }
    if (newA[sortBy] > newB[sortBy]) {
      return 1
    }
    return 0
  })
}

/**
 * Utility function for finding all children in a given tree for the specified node
 * @typedef NodeWithChildrenType
 * @param node - tree node (must have children and id)
 * @returns Array<number>
 */

/** @type {NodeWithChildrenType} */
export type NodeWithChildrenType = {
  children: Array<NodeWithChildrenType>
  id: string | number
}

export function findAllChildren(node: NodeWithChildrenType) {
  let res = []
  if (node.children.length === 0) {
    return [node.id]
  }
  node.children.forEach((child) => {
    res = res.concat(findAllChildren(child))
  })
  return res.concat([node.id])
}

export function scrollElementToTop(className: string) {
  const scrollElement = document.querySelector(className)
  if (scrollElement !== null) {
    scrollElement.scroll({ top: 0, behavior: 'smooth' })
  }
}

export const isArticleMedia = (mediaType) => BROADCAST_MEDIA_TYPES.indexOf(mediaType) !== -1

/**
 * Converts a large number to a shorter tuple, e.g. 23548 will be converted into ['24', 'k.'] and 1343872 into ['1.34', 'mill.']
 * @param {number} num - Large number to get converted.
 * @returns Return a tuple of the formatted number and it's suffix
 */
export const formatLargeNumber = (num: number): [string, 'mill.' | 'k.' | ''] => {
  if (num > 1e6) {
    return [(num / 1e6).toFixed(num > 1e7 ? 1 : 2), 'mill.']
  } else if (num > 1e4) {
    return [(num / 1e3).toFixed(1), 'k.']
  } else {
    return [num?.toString(), '']
  }
}

export const mergedPrevAndCurrentPeriod = (currentPeriod, prevPeriod) => {
  const documentsCurrent = documentsAddPeriod(currentPeriod, 'current')
  const documentsPrevious = documentsAddPeriod(prevPeriod, 'previous')

  const mergedPeriods = [documentsCurrent, documentsPrevious]

  return mergedPeriods.flat()
}

const documentsAddPeriod = (period, periodName) => {
  period.map((element) => {
    return (element['period'] = periodName)
  })
  return period
}

/**
 * Debounce delays triggering of the given function, using a setTimeout.
 * @param func The function that needs to be delayed
 * @param timeout Milliseconds, the function is delayed by
 */
export const debounce = (func, timeout) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

/**
 * Checks if children of input elements is checked.
 * @param elemId Id of the parent element, which holds these children of input elements.
 * @returns Returns the number of checked input elements.
 */
export const childrenInputElementsChecked = (elemId: string) => {
  const checkboxGroup = document.getElementById(elemId)
  if (checkboxGroup) {
    const inputChildren = Array.from(checkboxGroup.querySelectorAll('input'))
    const checkedArr = inputChildren.map((box) => box.checked).filter((isChecked) => isChecked)
    return checkedArr.length
  }
}

/**
 * Offer the function a unix time stamp, and it will tell you if it's within this year or not.
 * @param unix Unix time stamp
 * @returns {boolean} True or false.
 */
export const isCurrentYear = (unix: number) => {
  const currentYear = dayjs(new Date()).year()
  const yearFromUnix = dayjs(unix).year()

  return yearFromUnix === currentYear
}

/**
 * Changing the timePeriod filter to only show time, if a specific time has been chosen by the user.
 * @param filter
 * @returns {String} Returns a string with or without time, depending on period chosen.
 */
export const alterTimeperiod = (filter) => {
  const startOfDay = '00:00'
  const endOfDay = '23:59'
  const splittedFilter = filter && filter?.split('-')
  let startDate = splittedFilter[0]?.split(' ').filter(Boolean) // Splits the string and removes empty ones from array.
  let endDate = splittedFilter[1]?.split(' ').filter(Boolean) // Splits the string and removes empty ones from array.

  // Reduced into one function, because endDate and startDate are being handled the exact same way.
  const displayDate = (date: any[], timeOfDay: string) => {
    const displayYear = !isCurrentYear(+new Date(date?.join(' ')))

    if (date && date.includes(timeOfDay)) {
      // If startOfDay is included in the string then show the date without time.
      if (date[0] === timeOfDay) {
        // If only the time is shown, display only that.
        return date[0]
      } else {
        return `${date[0]} ${date[1]} ${date.length > 3 && displayYear ? date[2] : ''}`
      }
    } else {
      // In cases where dates are spans across multiple years
      if (displayYear) {
        // Join the array of strings and show the full date (With time, and year if it's the case).
        return date && date.join(' ')
      } else {
        // Otherwise remove the index, where year appears, and then join and display the date.
        date.splice(2, 1).join(' ') // Removes year from index 2.
        return date.join(' ') // Join date without year.
      }
    }
  }

  startDate = displayDate(startDate, startOfDay)
  endDate = displayDate(endDate, endOfDay)

  if (startDate === endDate) {
    return startDate
  } else {
    return `${startDate ? startDate : ''} ${endDate ? '- ' + endDate : ''}`
  }
}

/**
 * Getting the timePeriod from searchFilter and converting it into values that can be used to decide, which data should be displayed in the listingBar.
 * @param timeFilter
 * @returns {Object} Object which holds data for comparison of startDate and endDate
 */
export const getTimeperiodAndConvert = (timeFilter) => {
  // Get timePeriod filter
  const chosenPeriod = R.values(timeFilter).filter((filter) => filter.type === 'timePeriod')
  const exist = chosenPeriod.length > 0
  const splitPeriods = exist && chosenPeriod[0].id.split('-')
  const startDateIsToday = dayjs(splitPeriods[0] * 1000).format('DD-MM-YY') === dayjs(new Date()).format('DD-MM-YY')

  const convertedIntoHoursAndMinutes =
    exist &&
    chosenPeriod[0].id.split('-').map((period) => {
      const date = dayjs(+period * 1000)
      return `${date.hour()}:${date.minute()}`
    })

  return {
    start: { time: convertedIntoHoursAndMinutes[0], today: startDateIsToday },
    end: { time: convertedIntoHoursAndMinutes[1] },
  }
}

/**
 * Decides wether the displayed date should contain year and/or time.
 * @param range Date in unix time stamp
 * @param filter Generated object from the timePeriod filter
 * @param time A parameter to decicdes wether the time should be shown or not
 * @param i18n A parameter for translation
 * @returns Returns a date format depending on date given
 */
export const getFormat = (range: number, filter: any, time: '0:0' | '23:59') => {
  const sevenDaysInMilliseconds = 604800000

  if (filter.time === time && +new Date() - range < sevenDaysInMilliseconds) {
    return filter.today ? i18n.t('Today') : OpointDate.customFormat(range, 'dddd')
  }
  return addYearIfNotCurrent(range)
}

/**
 * Adding year to the date, if it's outside of the current year.
 * @param range
 * @returns Returns a date format with or without year.
 */
export const addYearIfNotCurrent = (range: number) => {
  if (isCurrentYear(range)) {
    return OpointDate.humanFormat(range)
  } else {
    return OpointDate.customFormat(range, 'MMM Do, YYYY')
  }
}

/**
 * Returns a boolean depending on wether the user is permitted or not.
 * @param module
 * @param permissions
 * @returns {boolean} Returns true or false
 */
export const isUserPermitted = (module: Modules, permissions: Options[]) => {
  const userPermissions = getUserPermissions(module)(store.getState())
  return !!permissions.filter((permission) => permission === userPermissions).length
}

/**
 * If more than one tag is selected, run this function to convert the filters into a search query.
 *
 * The backend does not support multiple tags, in the form of a filter.
 * @param filters Array of filters
 * @returns {string} Returns a search query
 */
const convertToSearchQuery = (filters: any[]) => {
  const convertToQuery = filters
    .map((filter) => {
      return `${filter.type}:${filter.id}`
    })
    .join(' OR ')

  return convertToQuery
}

/**
 * If multiple tags are chosen, we will handle it here, and convert the filters into a search query.
 * @param searchline
 * @returns {string} Returns a search query
 */
export const multipleTagsHandling = (searchline) => {
  let tempSearchLine
  const tagsQuery = convertToSearchQuery(searchline.filters)

  tempSearchLine = { filters: [] }

  if (!!searchline.searchterm) {
    tempSearchLine = { searchterm: tagsQuery + ' AND ' + searchline.searchterm }
  } else {
    tempSearchLine = { searchterm: tagsQuery }
  }
  return tempSearchLine
}

/**
 * Reducing duplicate snippets into one object with multiple matches.
 * @returns Array of objects containing snippets, multiple matches and seconds.
 */
export const reduceDuplicateSnippetOccurrence = (mediaMatches: any[]) => {
  // Finding duplicate snippets by mapping and filtering
  // This will generate duplicate objects, that we'll be removing below this map.
  const duplicateSnippets = mediaMatches.map((match) => {
    const seconds = match.play_offset
    const snippet = match?.quote?.text

    const matchArr = []
    const temp = mediaMatches.filter((m) => m.quote?.text === snippet)

    // There might be matches without snippets, but with an play_offset that varys from seconds to minutes.
    // In those cases we would like to show each match with it's different offset, to show the user when it's mentioned.
    if (snippet) {
      temp.map((m) => {
        matchArr.push(m.text)
      })
    } else {
      matchArr.push(match.text)
    }

    return { matches: matchArr, seconds: seconds, snippet: snippet, video_id: match.video_id }
  })

  // Reducing the duplicate objects by comparing snippets.
  const uniqueArray = duplicateSnippets.filter((v, i, a) => {
    // This check is due to what's mentioned above, with the other snippet check.
    // If no snippet, just return the match.
    if (v.snippet) {
      return a.findIndex((t) => t.snippet === v.snippet) === i
    } else {
      return v
    }
  })

  return uniqueArray
}
