import R from 'ramda'
import dayjs from 'dayjs'
import { createSelector } from 'reselect'

import { getSearch as getState, getTags, getProfiles, getFolders } from './rootSelectors'
import { getStartAndEndFromId, defaultStartEndDates } from '../opoint/search'

export const getSearchFilters = createSelector(getState, (searchState) => searchState.searchFilters)

export const getSearchLineFiltersIds = createSelector(getState, (searchState) =>
  R.values(R.map(({ id }) => +id, searchState.searchFilters)),
)

export const getSuggestions = createSelector(getState, (searchState) => searchState.suggestions)
export const getMainSearchLine = createSelector(getState, (searchState) => ({
  searchterm: searchState.searchterm,
  // Add all filters except timePeriod - that filter is passed as param
  // @ts-ignore
  filters: Object.values(R.filter((filter) => filter.type !== 'timePeriod', searchState.searchFilters)),
}))

export const getMainSearchLineWithTimePeriod = createSelector(getState, (searchState) => ({
  searchterm: searchState.searchterm,
  filters: Object.values(searchState.searchFilters),
}))

export const getSearchterm = createSelector(getState, (searchState) => searchState.searchterm)

export const isSearchNotEmpty = createSelector(
  getSearchFilters,
  getSearchterm,
  (searchFilters, searchterm) => !R.isEmpty(searchFilters) || !R.isEmpty(searchterm),
)

export const getTrashTagFilterIds = createSelector(
  getState,
  (searchState: any): Array<number> => searchState.trashTagIds,
)

export const isProfileSelected = (profileId: number) =>
  createSelector(getSearchFilters, (filters) => !!filters[`profile:${profileId}`])

export const isTagSelected = (tagId: number) => createSelector(getSearchFilters, (filters) => !!filters[`tag:${tagId}`])

export const getSelectedTagIds = createSelector(
  getState,
  (searchState: any): Array<number> => searchState.selectedTagIds,
)

export const getOneTagId = createSelector(getSelectedTagIds, (selectedTagIds: Array<number>): number =>
  R.head(selectedTagIds),
)

export const isOneTagSelected = createSelector(getSelectedTagIds, (selectedTagIds) => selectedTagIds.length === 1)

export const atLeastOneTagSelected = createSelector(getSelectedTagIds, (selectedTagIds) => !!selectedTagIds.length)

export const isTrashTagSelected = (tagId: number) =>
  createSelector(getSearchFilters, (filters) => !!filters[`trash:${tagId}`])

export const isStatisticSelected = (statId: number) =>
  createSelector(getSearchFilters, (filters) => filters[`chart:${statId}`])

export const getSelectedProfilesIds = createSelector(
  getState,
  (searchState: any): Array<number> => searchState.profileTagIds,
)

export const getSelectedProfilesNames = createSelector(
  getState,
  getProfiles,
  (searchState: any, profiles: any): Array<number> =>
    profiles.list
      .filter((profile) => searchState.profileTagIds.some((id) => id === profile.id))
      .map((profile) => profile.name),
)

export const getSelectedTagNames = createSelector(
  getState,
  getTags,
  (searchState: any, tags: any): Array<number> =>
    tags.list.filter((tag) => searchState.selectedTagIds.some((id) => id === tag.id)).map((tag) => tag.name),
)

export const getSelectedProfilesAndTagsIds = createSelector(
  getSelectedProfilesIds,
  getSelectedTagIds,
  (selectedProfilesIds: Array<number>, selectedTagIds: Array<number>): Array<number> =>
    selectedProfilesIds.concat(selectedTagIds),
)

export const getProfileTagList = createSelector(getTags, getProfiles, getFolders, (tags, profiles, folders) => {
  const frontPagesFolder = folders.folders?.find((folder) => folder.traits === 4)
  const newProfiles = !!frontPagesFolder
    ? profiles.list.filter((profile) => profile.folder !== frontPagesFolder.id)
    : []
  const newTags = tags.list.filter((tag) => tag.category === 0)

  return newTags.concat(newProfiles)
})

export const getSearchMeta = createSelector(getState, (search) => search.meta)

export const getSearchMetaRangeStart = createSelector(getSearchMeta, (meta) => {
  const start = dayjs(meta.rangeStart, 'x')
  return start.isValid() && start.toISOString()
})

export const getSearchMetaRangeEnd = createSelector(getSearchMeta, (meta) => {
  const end = dayjs(meta.rangeEnd, 'x')
  return end.isValid() && end.toISOString()
})

export const getReceivedDocumentsCount = createSelector(getState, (search) => search.meta.receivedDocumentsCount)

export const searchIsFinished = createSelector(getSearchMeta, (meta) => meta.context === '')

// get start and end date from Search datepicker
export const getSearchDatepicker = createSelector(getState, (search) => search.searchDatepicker)

// get start and end date for search query from state
export const getSearchTimePeriod = createSelector(getState, (state) =>
  R.compose(
    R.head,
    R.values,
    // @ts-ignore
    R.filter((f) => f.type === 'timePeriod'),
  )(state.searchFilters),
)

/*
  Order by search dates are set:
    1. search.searchDatepicker
    2. state.search.searchFilter.timePeriod.id
    3. default
*/
export const getSearchDates = createSelector(getSearchDatepicker, getSearchTimePeriod, (datePicker, timePeriod) => {
  if (datePicker) {
    return datePicker
  }
  // if (timePeriod) {
  //   // @ts-ignore
  //   return getStartAndEndFromId(timePeriod)
  // }
  return defaultStartEndDates()
})

// get start and end date range from actually loaded articles
export const getSearchActuallyLoadedDaterange = createSelector(getState, (state) => ({
  startDate: state.meta.lastTimestamp,
  endDate: state.meta.firstTimestamp,
}))

export const isSearchInProgress = createSelector(getState, (state) => state.searchInProgress)

export const isSearchIsTakingTooLong = createSelector(getState, (state) => state.searchIsTakingTooLong)

export const getSuggestionsMultiple = createSelector(getState, (state) => state.suggestionsMultiple)

export const getFiltersShowMore = createSelector(getState, (state) => state.filtersShowMore)

export const getLoadMoreSearchInProgress = createSelector(getState, (state) => state.loadMoreSearchInProgress)

export const getAbandonedSearchLine = createSelector(getState, (state) => state.abandonedSearchLine)

export const getWikinames = createSelector(getState, (state) => state.wikinames)

export const getWikidescriptions = createSelector(getState, (state) => state.wikidescriptions)

export const getCompareStatisticsFetched = createSelector(getState, (state) => state.compareStatisticsFetched)
