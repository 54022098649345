import { createSelector } from 'reselect'

import { getFolders as getState } from './rootSelectors'

export const getSortedFolders = createSelector(getState, (foldersState) => {
  const filteredFolders = foldersState.folders
  const sortedFolders = filteredFolders.sort((a, b) => b.sorting - a.sorting)
  return sortedFolders
})

export const getAllFolders = createSelector(getState, (foldersState) => foldersState.folders)

export const getFoldersTree = createSelector(getState, (foldersState) => foldersState.foldersTree)
